import { Outlet, RouteObject } from "react-router-dom";

import PrivateRoutes from "./components/PrivateRoutes";
import ForgetPasswordPage from "./pages/forgetPassword/ForgetPasswordPage";
import ResetPasswordPage from "./pages/resetPassword/ResetPasswordPage";

import rootLoader from "./loaders/rootLoader";
import { Extension } from "./models/intalled-extensions";
import { Domains } from "./models/permission";
import Authorization from "./pages/authorizations/Authorization";
import AuthorizationsPage from "./pages/authorizations/AuthorizationsPage";
import CardsPage from "./pages/CardsPage";
import ChangePasswordPage from "./pages/changePassword/changePasswordPage";
import ConfirmEmailPage from "./pages/ConfirmEmail";
import DocumentsPage from "./pages/Documents";
import ErrorPage from "./pages/errorPage/ErrorPage";
import EvaluationFormPage from "./pages/EvaluationFormPage";
import ExamsPage from "./pages/Exams/ExamsPage";
import ExtractCoParticipationPage from "./pages/ExtractCoParticipationPage";
import FaqPage from "./pages/faq/FaqPage";
import HealthProgram from "./pages/HealthProgram/HealthProgram";
import HealthProgramContentPage from "./pages/HealthProgram/HealthProgramContentPage";
import HealthProgramsPage from "./pages/HealthProgram/HealthPrograms";
import HistoryPaymentPage from "./pages/HistoryPaymentPage";
import HomePage from "./pages/homePage";
import IrpfPage from "./pages/IrpfPage";
import LoginPage from "./pages/loginPage/LoginPage";
import MedicGuidePage from "./pages/MedicGuidePage";
import MyRegistrationPage from "./pages/MyRegistrationPage/MyRegistrationPage";
import AllNewsPage from "./pages/News/AllNewsPage";
import News from "./pages/News/News";
import NoAccessPage from "./pages/NoAccessPage";
import Notification from "./pages/Notification/Notification";
import Notifications from "./pages/Notification/Notifications";
import Refund from "./pages/refunds/RefundsForm/Refund";
import RefundsPage from "./pages/refunds/RefundsPage";
import RegisterPage from "./pages/registerPage/RegisterPage";
import RootPage from "./pages/rootPage";
import CheckedInPage from "./pages/Schedule/CheckedIn/CheckedInPage";
import AddFamilyGroup from "./pages/Schedule/FamilyGroup/AddFamilyGroup/AddFamilyGroup";
import FamilyGroup from "./pages/Schedule/FamilyGroup/FamilyGroup";
import AddInsurance from "./pages/Schedule/Insurance/AddInsurance/AddInsurance";
import Insurance from "./pages/Schedule/Insurance/Insurance";
import MySchedules from "./pages/Schedule/MySchedules/MySchedules";
import ScheduleDetails from "./pages/Schedule/MySchedules/ScheduleDetails/ScheduleDetails";
import NewSchedule from "./pages/Schedule/NewSchedule/NewSchedule";
import ScheduleHome from "./pages/Schedule/Schedule";
import EmergencyCareScheduling from "./pages/virtualEmergencyCare/EmergencyCareScheduling";
import ScheduleTeleconsultationPage from "./pages/virtualEmergencyCare/ScheduleTeleconsultationPage";
import VirtualEmergencyCarePage from "./pages/virtualEmergencyCare/VirtualEmergencyCarePage";
import WhatsApp from "./pages/WhatssApp";
import { tokenService } from "./services/token.service";
import DomainContextProvider from "./store/DomainContext";
import ExtensionsContextProvider from "./store/ExtensionsContext";
import ExternalCalendarButtonPage from "./pages/externalCalendarButton/ExternalCalendarButtonPage";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <RootPage />,
    loader: rootLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/schedule",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.SCHEDULE }}
          >
            <Outlet />
          </ExtensionsContextProvider>
        ),
        children: [
          {
            index: true,
            element: <ScheduleHome />,
          },
          {
            path: "insurance",
            element: <Insurance />,
          },
          {
            path: "insurance/add",
            element: <AddInsurance />,
          },
          {
            path: "insurance/edit/:id",
            element: <AddInsurance />,
          },
          {
            path: "mySchedules",
            element: <MySchedules />,
          },
          {
            path: "mySchedules/:tasySchedulingTypeCode/:identifier",
            element: <ScheduleDetails />,
          },
          {
            path: "mySchedules/:tasySchedulingTypeCode/:identifier/checkedIn",
            element: <CheckedInPage />,
          },
          {
            path: "familyGroup",
            element: <FamilyGroup />,
          },
          {
            path: "familyGroup/add",
            element: <AddFamilyGroup />,
          },
          {
            path: "familyGroup/edit/:id",
            element: <AddFamilyGroup />,
          },
          {
            path: "newSchedule/:initialStep?",
            element: <NewSchedule />,
          },
        ],
      },

      {
        path: "/cards",
        element: (
          <ExtensionsContextProvider value={{ extensionCode: Extension.CARDS }}>
            <CardsPage />
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "medic-guide/:search/*",
        element: <MedicGuidePage />,
      },
      {
        path: "/history-payment",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.PAYMENTSLIP }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.PAYMENTSLIP }}
              actions={["Read"]}
            >
              <HistoryPaymentPage />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/extract-co-participation",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.COPARTICIPATION }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.COPARTICIPATION }}
              actions={["Read"]}
            >
              <ExtractCoParticipationPage />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/irpf",
        element: (
          <ExtensionsContextProvider value={{ extensionCode: Extension.IRPF }}>
            <DomainContextProvider
              value={{ domainName: Domains.IRPF }}
              actions={["Read"]}
            >
              <IrpfPage />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/schedule-teleconsultation",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.EMERGENCY_CARE }}
          >
            <ScheduleTeleconsultationPage />
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/schedule-teleconsultation/appointment-scheduling",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.EMERGENCY_CARE }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.EMEGENCY_CARE }}
            >
              <EmergencyCareScheduling />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/schedule-teleconsultation/virtual-emergency-care/:id",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.EMERGENCY_CARE }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.EMEGENCY_CARE }}
            >
              <VirtualEmergencyCarePage />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/authorizations",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.AUTHORIZATIONS }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.AUTH }}
              actions={["Read"]}
            >
              <AuthorizationsPage />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/authorizations/:initialDate/:endDate/:id",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.AUTHORIZATIONS }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.AUTH }}
              actions={["Read"]}
            >
              <Authorization />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/refunds",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.REFUND }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.REFUND }}
              actions={["Read"]}
            >
              <RefundsPage />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/refunds/new",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.REFUND }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.REFUND }}
              actions={["Read"]}
            >
              <Refund />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/change-password",
        element: (
          <PrivateRoutes
            canAccess={() => tokenService.hasAccessTokens()}
            redirectUrl="/login"
          >
            <ChangePasswordPage />
          </PrivateRoutes>
        ),
      },
      {
        path: "/faq",
        element: (
          <ExtensionsContextProvider value={{ extensionCode: Extension.FAQ }}>
            <FaqPage />
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "/externalCalendarButton",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.EXTERNAL_CALENDAR_BUTTON }}
          >
            <ExternalCalendarButtonPage />
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "news",
        element: (
          <ExtensionsContextProvider value={{ extensionCode: Extension.NEWS }}>
            <AllNewsPage />
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "news/:id",
        element: (
          <ExtensionsContextProvider value={{ extensionCode: Extension.NEWS }}>
            <News />
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "notifications",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.NOTIFICATIONS }}
          >
            <DomainContextProvider
              value={{ domainName: "notification" }}
              actions={["Read"]}
            >
              <Notifications />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "notifications/:id",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.NOTIFICATIONS }}
          >
            <DomainContextProvider
              value={{ domainName: "notification" }}
              actions={["Read"]}
            >
              <Notification />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "whats",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.WHATSAPP }}
          >
            <WhatsApp />
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "health-programs",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.HEALTH_PROGRAM }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.HEALTH_PROGRAM }}
              actions={["Read"]}
              visitUserCanAccess
            >
              <HealthProgramsPage />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "health-programs/:id/:contentId",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.HEALTH_PROGRAM }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.HEALTH_PROGRAM }}
              actions={["Read"]}
              visitUserCanAccess
            >
              <HealthProgramContentPage />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "health-programs/:id",
        element: (
          <ExtensionsContextProvider
            value={{ extensionCode: Extension.HEALTH_PROGRAM }}
          >
            <DomainContextProvider
              value={{ domainName: Domains.HEALTH_PROGRAM }}
              actions={["Read"]}
              visitUserCanAccess
            >
              <HealthProgram />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "exams",
        element: (
          <ExtensionsContextProvider value={{ extensionCode: Extension.EXAMS }}>
            <DomainContextProvider
              value={{ domainName: Domains.EXAMS }}
              redirectUrlNotLogged="/login/exams"
              isTemporaryAccessPage
              actions={["Read"]}
            >
              <ExamsPage />
            </DomainContextProvider>
          </ExtensionsContextProvider>
        ),
      },
      {
        path: "my-registration",
        element: <MyRegistrationPage />,
      },
      {
        path: "docs",
        element: <DocumentsPage />,
      },
      {
        path: "no-access",
        element: <NoAccessPage />,
      },
    ],
  },
  {
    path: "login/:type?",
    element: (
      <PrivateRoutes
        canAccess={() => !tokenService.hasAccessTokens()}
        redirectUrl="/"
      >
        <LoginPage />
      </PrivateRoutes>
    ),
  },
  {
    path: "evaluationForm/:id",
    element: <EvaluationFormPage />,
  },
  {
    path: "forget-password",
    element: <ForgetPasswordPage />,
  },
  {
    path: "email-confirmation/:userName?",
    element: <ConfirmEmailPage />,
  },
  {
    path: "reset-password/:userName",
    element: <ResetPasswordPage />,
  },
  {
    path: "register",
    element: <RegisterPage />,
  },
];

export default routes;
