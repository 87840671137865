import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useExtensionConfigs } from "../../../hooks/useExtensionConfigs";
import { useInstalledExtensions } from "../../../hooks/useInstalledExtensions";
import { IExtensionConfig } from "../../../models/extensions";
import { Extension } from "../../../models/intalled-extensions";
import { INavigationItem } from "../../../models/navigation";
import { Domains } from "../../../models/permission";
import { tokenService } from "../../../services/token.service";
import { tokenTemporaryService } from "../../../services/tokenTemorary.service";
import { useAppSelector } from "../../../store/store";

const translationPath = "page.home.";

export const useNavigationItems = () => {
  const { t } = useTranslation();

  const { fullName } = useAppSelector((state) => state.user);

  const sideMenu = useAppSelector((state) => state.theme.sideMenu);

  const temporaryName = tokenTemporaryService.getTemporaryAccessName();

  const isLogged = !!tokenService.getAccessToken();

  const [faqInstalled] = useInstalledExtensions(Extension.FAQ);

  const [externalCalendarButtonInstalled] = useInstalledExtensions(
    Extension.EXTERNAL_CALENDAR_BUTTON
  );

  const [refundInstalled] = useInstalledExtensions(Extension.REFUND);

  const [irInstalled] = useInstalledExtensions(Extension.IRPF);

  const [ticketInstalled] = useInstalledExtensions(Extension.PAYMENTSLIP);

  const [examsInstalled] = useInstalledExtensions(Extension.EXAMS);

  const [whatsappInstalled] = useInstalledExtensions(Extension.WHATSAPP);

  const [coparticipationInstalled] = useInstalledExtensions(
    Extension.COPARTICIPATION
  );

  const [authorizationsInstalled] = useInstalledExtensions(
    Extension.AUTHORIZATIONS
  );

  const [healthProgramInstalled] = useInstalledExtensions(
    Extension.HEALTH_PROGRAM
  );

  const [actionLabelText, setActionLabelText] = useState<IExtensionConfig>();

  const [actionLabelIcon, setActionLabelIcon] = useState<IExtensionConfig>();

  const [open, setOpen] = useState(false);

  const onOpenHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const onCloseHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const { fetchExtensionConfig } = useExtensionConfigs();
  const fetchExtensions = useCallback(async () => {
    const configActionLabelText = await fetchExtensionConfig(
      Extension.EXTERNAL_CALENDAR_BUTTON,
      "ActionLabelText"
    );
    setActionLabelText(configActionLabelText);

    const configActionLabelIcon = await fetchExtensionConfig(
      Extension.EXTERNAL_CALENDAR_BUTTON,
      "ActionLabelIcon"
    );
    setActionLabelIcon(configActionLabelIcon);
  }, [fetchExtensionConfig]);

  useEffect(() => {
    fetchExtensions();
  }, [fetchExtensions]);

  const [scheduleInstalled] = useInstalledExtensions(Extension.SCHEDULE);

  const hasUser = useMemo(
    () => fullName || temporaryName,
    [fullName, temporaryName]
  );
  const items: INavigationItem[] = useMemo(
    () => [
      {
        name: t(translationPath + actionLabelText?.value),
        link: "/externalCalendarButton",
        icon: (
          <img
            style={{ height: 60 }}
            src={actionLabelIcon?.value}
            alt={
              t(translationPath + actionLabelText?.value) || "Agendamento App"
            }
          ></img>
        ),
        isExtensionInstalled: externalCalendarButtonInstalled,
      },
      {
        name: t(translationPath + "schedule"),
        link: "/schedule",
        icon: (
          <img
            style={{ height: 75 }}
            src="/actions/schedule.png"
            alt={t(translationPath + "schedule") || "Schedule"}
          ></img>
        ),
        isExtensionInstalled: scheduleInstalled,
        onlyLogged: false,
        onClick: !hasUser ? onOpenHandler : undefined,
      },
      {
        name: "Whatsapp",
        link: "/whats",
        icon: (
          <img
            style={{ height: 75 }}
            src="/actions/whatsapp.png"
            alt={t(translationPath + "health_program") || "Health Program"}
          />
        ),
        isExtensionInstalled: !sideMenu && whatsappInstalled,
      },
      {
        name: t(translationPath + "exams"),
        link: hasUser ? "/exams" : "/login/exams",
        icon: (
          <img
            style={{ height: 75 }}
            src="/actions/exam-lab.png"
            alt={t(translationPath + "health_program") || "Health Program"}
          />
        ),
        domain: isLogged ? Domains.EXAMS : undefined,
        isExtensionInstalled: examsInstalled,
      },
      {
        name: t(translationPath + "health_program"),
        link: "/health-programs",
        icon: (
          <img
            style={{ height: 70 }}
            src="/actions/healthprogram.png"
            alt={t(translationPath + "health_program") || "Health Program"}
          ></img>
        ),
        isExtensionInstalled: healthProgramInstalled,
        domain: isLogged ? Domains.HEALTH_PROGRAM : undefined,
      },
      {
        name: t(translationPath + "ticket"),
        link: "/history-payment",
        icon: (
          <img
            style={{ height: 60 }}
            src="/actions/money.png"
            alt={t(translationPath + "ticket") || "Duplicate ticket"}
          ></img>
        ),
        domain: Domains.PAYMENTSLIP,
        isExtensionInstalled: ticketInstalled,
      },
      {
        name: t(translationPath + "authorization"),
        link: "/authorizations",
        icon: (
          <img
            style={{ height: 60 }}
            src={"/actions/authorization.png"}
            alt={t(translationPath + "coParticipation") || "Authorizations"}
          ></img>
        ),
        domain: Domains.AUTH,
        isExtensionInstalled: authorizationsInstalled,
      },
      {
        name: t(translationPath + "coParticipation"),
        link: "/extract-co-participation",
        icon: (
          <img
            style={{ height: 60 }}
            src={"/actions/coparticipacao.png"}
            alt={
              t(translationPath + "coParticipation") ||
              "Co-participation extract"
            }
          ></img>
        ),
        domain: Domains.COPARTICIPATION,
        isExtensionInstalled: coparticipationInstalled,
      },
      {
        name: t(translationPath + "irAndReimbursement"),
        link: "/irpf",
        icon: (
          <img
            style={{ height: 60 }}
            src={"/actions/ir.png"}
            alt={t(translationPath + "irAndReimbursement") || "IR and Refund"}
          ></img>
        ),
        domain: Domains.IRPF,
        isExtensionInstalled: irInstalled,
      },
      {
        name: t(translationPath + "refunds"),
        link: "/refunds",
        icon: (
          <img
            style={{ height: 60 }}
            src={"/actions/refund.png"}
            alt={t(translationPath + "refunds") || "Refunds"}
          ></img>
        ),
        domain: Domains.REFUND,
        isExtensionInstalled: refundInstalled,
      },
      {
        name: t(translationPath + "faq"),
        link: "/faq",
        icon: (
          <img
            style={{ height: 60 }}
            src={"/actions/faq.png"}
            alt={t(translationPath + "refunds") || "Refunds"}
          ></img>
        ),
        isExtensionInstalled: faqInstalled,
      },
    ],

    [
      t,
      faqInstalled,
      refundInstalled,
      irInstalled,
      whatsappInstalled,
      sideMenu,
      coparticipationInstalled,
      authorizationsInstalled,
      ticketInstalled,
      healthProgramInstalled,
      isLogged,
      examsInstalled,
      hasUser,
      scheduleInstalled,
      externalCalendarButtonInstalled,
      actionLabelText,
      actionLabelIcon,
      onOpenHandler,
    ]
  );

  return {
    items,
    open,
    onOpenHandler,
    onCloseHandler,
  };
};
