import { ISelectType } from "../components/UI/Inputs/AutoComplete";

interface ISiteApp {
  id: string;
  name: string;
  description: string;
  siteAppStatus: ISelectType;
}

interface IBannerMediaFile {
  id: string;
  mediaTypeId: string;
  isPublic: true;
  fileName: string;
  cdnDomain: string;
  filePath: string;
  mimeType: string;
  fileSize: number;
  mediaType: ISelectType;
}

export enum EBannerLocale {
  OPEN_SITE_APP = "2",
  LOGIN_IN_SYSTEM = "1",
  MINI_BANNER_ABOVE = "3",
  MINI_BANNER_BELOW = "4",
  FULL_BANNER = "5",
}

interface IBannersLocals {
  code: EBannerLocale;
  description: null | string;
  id: string;
  name: string;
}

export interface IBanner {
  id: string;
  name: string;
  title: string;
  alternativeText: string;
  displayOrder: number;
  enable: boolean;
  language: ISelectType;
  siteApps: ISiteApp[];
  bannerLocals: IBannersLocals[];
  bannerMediaFiles: IBannerMediaFile[];
  lastModified: string;
  targetingLink?: string;
}

export interface IBannerRequestParams {
  LanguageId: string;
  BannerLocalCode?: string;
  OnlyNewBanners?: boolean;
}

export interface IPortalBanner {
  id: string;
  name: string;
  title: string;
  path: string;
  bannerLocals: IBannersLocals[];
  bannerMediaFiles: IBannerMediaFile[];
  alternativeText: string;
  date: string;
  targetingLink?: string;
}
