import { ThemeOptions } from "@mui/material";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TTheme } from "../../models/theme";
import { TAppreanceCode } from "./configurations";

const THEME_INITIAL_STATE: TTheme & ThemeOptions = {
  mode: "1",
  menuOpen: true,
  frontBackgroundColor: "#FFFFFF",
  backBackgroundColor: "#FFFFFF",
  backTargeColor: "#FFFFFF",
  name: "",
  logo: "",
  favicon: "",
  menuFavicon: undefined,
  description: "",
  isLoginCpf: false,
  sideMenu: true,
  logoInHeader: false,
  menuBackgroundColor: undefined,
  headerIconColor: undefined,
  actionButtonColor: undefined,
  actionButtonColorDark: undefined,
  typography: {
    button: {
      textTransform: "uppercase",
    },
  },
};

export const slice = createSlice({
  name: "theme",
  initialState: THEME_INITIAL_STATE,
  reducers: {
    toggleTheme(state: TTheme) {
      state.mode = state.mode === "1" ? "2" : "1";
      return state;
    },
    setThemeMode(state: TTheme, action: PayloadAction<TAppreanceCode>) {
      state.mode = action.payload;
      return state;
    },
    toggleMenu(state: TTheme) {
      return { ...state, menuOpen: !state.menuOpen };
    },
    setMenu(state: TTheme, action: PayloadAction<boolean>) {
      state.menuOpen = action.payload;
      return state;
    },
    setTheme(state: TTheme, action: PayloadAction<TTheme>) {
      state = { ...THEME_INITIAL_STATE, ...action.payload };
      return state;
    },
  },
});

export const themeSliceActions = slice.actions;

export default slice.reducer;
