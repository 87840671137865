import { Button, Modal } from "@4uhub/lib4uhub";
import { Box, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { INeedAuthModalProps } from "./models";

const translationPath = "components.needAuthModal.";

export const NeedAuthModal = memo(
  ({ open, onCloseHandler }: INeedAuthModalProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const confirmHandler = useCallback(() => {
      navigate("/login");
    }, [navigate]);

    return (
      <Modal open={open} onClose={onCloseHandler}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography sx={{ fontSize: 18 }}>
            {t(`${translationPath}description`)}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              gap: 1,
            }}
          >
            <Button onClick={onCloseHandler}>
              {t(`${translationPath}cancel`)}
            </Button>
            <Button variant="contained" onClick={confirmHandler}>
              {t(`${translationPath}confirm`)}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }
);
