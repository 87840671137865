import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import LanguageIcon from "@mui/icons-material/Language";
import { useState } from "react";
import { useAppSelector } from "../../../store/store";
import ChangeLanguageForm from "../../ChangeLanguageForm";
import ModalComponent from "../Modal";

const LanguageSwitch = () => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const appearance = useAppSelector((state) => state.theme);

  const openModalHandler = () => setOpenModal(true);
  const closeModalHandler = () => setOpenModal(false);

  return (
    <>
      <ModalComponent open={openModal} onClose={closeModalHandler}>
        <ChangeLanguageForm onClose={closeModalHandler} />
      </ModalComponent>
      <Tooltip
        title={t("components.languageSwitch.change_language")}
        placement="right"
      >
        <IconButton
          onClick={openModalHandler}
          color={appearance.headerIconColor ? undefined : "inherit"}
          size="small"
        >
          <LanguageIcon
            color={appearance.headerIconColor ? undefined : "action"}
            fontSize="small"
            sx={
              appearance.headerIconColor
                ? { color: appearance.headerIconColor }
                : undefined
            }
          />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default LanguageSwitch;
