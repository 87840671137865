import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { tokenService } from "../../../services/token.service";
import { NeedAuthModal } from "../../NeedAuthModal/NeedAuthModal";
import PermissionControl from "../PermissionControl";
import NavigatorCard from "./NavigatorCard";
import { useNavigationItems } from "./useNavigationItems";

const translationPath = "page.home.";

const NavigatorCardList = () => {
  const { t } = useTranslation();

  const isLogged = !!tokenService.hasAccessTokens();

  const { items, open, onCloseHandler } = useNavigationItems();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack>
      <NeedAuthModal open={open} onCloseHandler={onCloseHandler} />
      <Typography variant="subtitle1">
        {t(translationPath + "navigation_card_list")}
      </Typography>
      <Grid
        container
        flexDirection={"row"}
        gap={matches ? 2 : 3}
        wrap={matches ? "nowrap" : "wrap"}
        overflow={"auto"}
      >
        {items.map((item) => {
          if (item.onlyLogged && !isLogged) {
            return null;
          }

          return (
            <PermissionControl
              domain={item.domain}
              isExtensionInstalled={item.isExtensionInstalled}
              key={item.link}
            >
              <NavigatorCard {...item} />
            </PermissionControl>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default NavigatorCardList;
