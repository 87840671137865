import { Box, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import StepperComponent from "../../../components/UI/Stepper/Stepper";

import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Step from "../../../components/UI/Stepper/Step";
import useFetch from "../../../hooks/useFetch";
import RefundsService from "../../../services/refunds.service";
import { notificationSliceActions } from "../../../store/slices/notification";
import { useAppDispatch } from "../../../store/store";
import { TRefundForm, refundSchema } from "./RefundSchema";
import RefundSelectBeneficiary from "./RefundSelectBeneficiary";
import RefundFirst from "./RefundsFirst/RefundFirst";
import RefundSecond from "./RefundsSecond/RefundSecond";
import PagesContainer from "../../../components/UI/PagesContainer";

const refundService = new RefundsService();

const translationPath = "page.refundsPage.refund.";

const RefundsPage = () => {
  const { t } = useTranslation();
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [beneficiaryId, setBeneficiaryId] = useState("");
  const [individualId, setIndividualId] = useState("");
  const [deleteFilesList, setDeleteFilesList] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { sendRequest, loading } = useFetch(refundService.create);

  const steps = [
    `${t(translationPath + "select_beneficiary")}`,
    `${t(translationPath + "basic_data")}`,
    `${t(translationPath + "others_data")}`,
  ];

  const methods = useForm<TRefundForm>({
    resolver: zodResolver(refundSchema),
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const onSubmitHandler = useCallback(
    async (values: TRefundForm) => {
      const { success } = await sendRequest({
        ...values,
        typeGuideCode: values.typeGuideCode.code,
        phoneContact: values.phoneContact.replace("(", "").replace(")", ""),
        deleteMediaFilesIds: deleteFilesList,
        bankId: values.bank?.id || "",
        mediaFilesIds: values.files.map((item) => item.file.id),
        individualBeneficiaryId: individualId,
        serviceDate: values.serviceDate.toISOString(),
        serviceValue: +values.serviceValue
          .replaceAll(".", "")
          .replace(",", "."),
        holderBankAccountName: values.holderBankAccountName || null,
        holderBirthDate: values.holderBirthDate
          ? values.holderBirthDate.toISOString().substring(0, 10)
          : null,
        holderDocument:
          values.holderDocument?.replaceAll(".", "").replaceAll("-", "") ||
          null,
        bankBranch: values.bankBranch || null,
        bankAccount: values.bankAccount || null,
        bankAccountDigit: values.bankAccountDigit || null,
      });
      if (success) {
        navigate(-1);
        dispatch(
          notificationSliceActions.showNotification({
            message: `${t(translationPath + "refund_requested")}`,
            type: "success",
          })
        );
      }
    },
    [sendRequest, navigate, deleteFilesList, individualId, dispatch, t]
  );

  const onSelectBeneficiary = (
    name: string,
    individualBeneficiaryId: string,
    individualId: string
  ) => {
    setBeneficiaryName(name);
    setBeneficiaryId(individualBeneficiaryId);
    setIndividualId(individualId);
  };

  const deleteFile = (fileId: string) => {
    setDeleteFilesList((prevState) => [...prevState, fileId]);
  };

  return (
    <PagesContainer title={t(translationPath + "refund")}>
      <Grid container>
        <Grid item sm={12}>
          <FormProvider {...methods}>
            <Box
              component="form"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmitHandler)}
              paddingRight={2}
              width={"100%"}
              height={"calc(100vh - 12rem)"}
            >
              <StepperComponent
                steps={steps}
                loading={loading}
                lastButton={{
                  label: `${t(translationPath + "save")}`,
                  type: "submit",
                }}
                showFirstButton={false}
              >
                <Step step={0}>
                  <RefundSelectBeneficiary
                    onSelectBeneficiary={onSelectBeneficiary}
                  />
                </Step>
                <Step step={1}>
                  <RefundFirst
                    name={beneficiaryName}
                    onDeleteFilesHandler={deleteFile}
                  />
                </Step>
                <Step step={2}>
                  <RefundSecond beneficiaryId={beneficiaryId} />
                </Step>
              </StepperComponent>
            </Box>
          </FormProvider>
        </Grid>
      </Grid>
    </PagesContainer>
  );
};

export default RefundsPage;
