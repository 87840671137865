import { Box, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { INavigationItem } from "../../../models/navigation";
import Cards from "../Cards";

const NavigatorCard: FC<INavigationItem> = ({ icon, link, name, onClick }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onClickHandler = () => {
    if (onClick) {
      onClick();
      return;
    }
    navigate(link);
  };

  return (
    <Stack
      width={120}
      minWidth={120}
      onClick={onClickHandler}
      sx={(theme) => ({
        cursor: "pointer",
        "&:hover": {
          color: theme.palette.primary.main,
        },
      })}
    >
      <Cards width={"100%"}>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
          sx={{
            height: "90px",
            width: "100%",
          }}
        >
          <Box>{icon}</Box>
        </Stack>
      </Cards>
      <Typography textAlign={"center"} flexWrap={"wrap"} width={"100%"} mt={1}>
        {t(name)}
      </Typography>
    </Stack>
  );
};

export default NavigatorCard;
