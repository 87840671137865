import { IBanner, IPortalBanner } from "../../../models/banners";

export const transformBanner = (banner: IBanner): IPortalBanner => {
  let path = "";

  if (banner.bannerMediaFiles.length !== 0) {
    const firsthMidia = banner.bannerMediaFiles[0];

    path = firsthMidia.cdnDomain + "/" + firsthMidia.filePath;
  }

  return {
    id: banner.id,
    name: banner.name,
    bannerLocals: banner.bannerLocals,
    bannerMediaFiles: banner.bannerMediaFiles,
    title: banner.title,
    path: path,
    alternativeText: banner.alternativeText,
    date: new Date(banner.lastModified).toLocaleString("pt-BR", {
      timeZone: "America/Sao_Paulo",
    }),
    targetingLink: banner.targetingLink,
  };
};
