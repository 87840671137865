import { Paper, Stack, useTheme } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useInstalledExtensions } from "../../../hooks/useInstalledExtensions";
import usePermission from "../../../hooks/usePermission";
import { Extension } from "../../../models/intalled-extensions";
import { INavigationLink } from "../../../models/navigation";
import { tokenService } from "../../../services/token.service";
import { NeedAuthModal } from "../../NeedAuthModal/NeedAuthModal";
import SideMenuItem from "./SideMenuItem";

const BottomMenu = () => {
  const token = tokenService.hasAccessTokens();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const theme = useTheme();
  const [telephoneInstalled] = useInstalledExtensions(Extension.TELEPHONE);
  const [promptInstalled] = useInstalledExtensions(Extension.EMERGENCY_CARE);
  const [virtualCardInstalled] = useInstalledExtensions(Extension.CARDS);
  const [whatsappInstalled] = useInstalledExtensions(Extension.WHATSAPP);
  const [scheduleInstalled] = useInstalledExtensions(Extension.SCHEDULE);

  const [open, setOpen] = useState(false);

  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const hasPhonePermission = usePermission({
    domain: "telephonecall",
  });
  const hasTeleconsultationPermission = usePermission({
    domain: "promptservice",
  });
  const cardPermission = usePermission({
    domain: "card",
  });

  const items: INavigationLink[] = useMemo(() => {
    const initialItems: INavigationLink[] = [
      {
        name: t("components.menu.home"),
        iconClass: "HomeRoundedIcon",
        link: "/",
        end: true,
      },
    ];

    if (whatsappInstalled) {
      initialItems.push({
        name: t("components.menu.whats"),
        iconClass: "WhatsAppIcon",
        link: "/whats",
        end: false,
      });
    }

    if (scheduleInstalled) {
      initialItems.push({
        name: t("components.menu.schedule"),
        iconClass: "EventIcon",
        link: "/schedule",
        end: false,
        onClick: token ? undefined : openHandler,
      });
    }

    if (telephoneInstalled || promptInstalled) {
      if (hasPhonePermission || hasTeleconsultationPermission) {
        initialItems.push({
          name: t("components.menu.virtualEmergencyCare"),
          iconClass: "LocalHospitalIcon",
          link: "/schedule-teleconsultation",
          end: false,
        });
      }
    }

    if (virtualCardInstalled) {
      if (token) {
        if (cardPermission) {
          initialItems.splice(1, 0, {
            name: t("components.menu.cards"),
            iconClass: "CreditCardRoundedIcon",
            link: "/cards",
            end: false,
          });
        }
      } else {
        initialItems.splice(1, 0, {
          name: t("components.menu.cards"),
          iconClass: "CreditCardRoundedIcon",
          link: "/cards",
          end: false,
        });
      }
    }

    return initialItems;
  }, [
    t,
    hasPhonePermission,
    hasTeleconsultationPermission,
    telephoneInstalled,
    promptInstalled,
    virtualCardInstalled,
    cardPermission,
    scheduleInstalled,
    token,
    whatsappInstalled,
    openHandler,
  ]);

  const show =
    pathname === "/" ||
    pathname === "/cards" ||
    pathname === "/schedule-teleconsultation" ||
    pathname === "/schedule";

  if (!show) return null;

  return createPortal(
    <Paper
      sx={{
        width: "calc(100vw - 40px)",
        zIndex: 3,
        position: "absolute",
        bottom: 0,
        margin: "20px",
        backgroundColor: theme.palette.mode === "light" ? "#fff" : undefined,
      }}
      elevation={2}
    >
      <NeedAuthModal open={open} onCloseHandler={closeHandler} />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={1}
      >
        {items?.map((item) => (
          <SideMenuItem
            {...item}
            key={item.link || item.name}
            onlyIcon={true}
          />
        ))}
      </Stack>
    </Paper>,
    document.body
  );
};

export default BottomMenu;
