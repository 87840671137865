import { Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useCallback, useEffect, useState } from "react";
import PagesContainer from "../../components/UI/PagesContainer";
import { useExtensionConfigs } from "../../hooks/useExtensionConfigs";
import { IExtensionConfig } from "../../models/extensions";
import { Extension } from "../../models/intalled-extensions";
import { useAppSelector } from "../../store/store";

const translationPath = "page.home.";

const ExternalCalendarButtonPage = () => {
  const { t } = useTranslation();

  const [description, setDescription] = useState<IExtensionConfig>();
  const [googlePlayLink, setGooglePlayLink] = useState<IExtensionConfig>();
  const [appStoreLink, setAppStoreLink] = useState<IExtensionConfig>();
  const [actionLabelText, setActionLabelText] = useState<IExtensionConfig>();

  const { mode } = useAppSelector((state) => state.theme);

  const { fetchExtensionConfig } = useExtensionConfigs();
  const fetchExtensions = useCallback(async () => {
    const configActionLabelText = await fetchExtensionConfig(
      Extension.EXTERNAL_CALENDAR_BUTTON,
      "ActionLabelText"
    );
    setActionLabelText(configActionLabelText);

    const configDescription = await fetchExtensionConfig(
      Extension.EXTERNAL_CALENDAR_BUTTON,
      "Description"
    );
    setDescription(configDescription);

    const configGooglePlayLink = await fetchExtensionConfig(
      Extension.EXTERNAL_CALENDAR_BUTTON,
      "GooglePlayLink"
    );
    setGooglePlayLink(configGooglePlayLink);

    const configAppStoreLink = await fetchExtensionConfig(
      Extension.EXTERNAL_CALENDAR_BUTTON,
      "AppStoreLink"
    );
    setAppStoreLink(configAppStoreLink);
  }, [fetchExtensionConfig]);

  useEffect(() => {
    fetchExtensions();
  }, [fetchExtensions]);

  return (
    <PagesContainer title={t(translationPath + actionLabelText?.value)}>
      <Stack spacing={1} alignItems="center" justifyContent="center">
        {description && <Typography>{description.value}</Typography>}

        <Grid container spacing={2} justifyContent="center">
          {googlePlayLink && (
            <Grid item>
              <a
                href={googlePlayLink ? googlePlayLink.value : ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    mode === "1" ? "/play_store_dark.png" : "/play_store.png"
                  }
                  alt={"play-store"}
                  style={{
                    width: 220,
                  }}
                />
              </a>
            </Grid>
          )}
          {appStoreLink && (
            <Grid item>
              <a
                href={appStoreLink ? appStoreLink.value : ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={mode === "1" ? "/app_store_dark.png" : "/app_store.png"}
                  alt={"app-store"}
                  style={{
                    width: 220,
                  }}
                />
              </a>
            </Grid>
          )}
        </Grid>
      </Stack>
    </PagesContainer>
  );
};

export default ExternalCalendarButtonPage;
