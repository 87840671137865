import { IconButton, Tooltip } from "@mui/material";
import { motion } from "framer-motion";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../store/store";

import { tokenService } from "../../services/token.service";
import { themeSliceActions } from "../../store/slices/theme";
import { saveConfig } from "../../store/thunks/ConfigurationsThunk";

import ContrastIcon from "@mui/icons-material/Contrast";

const ThemeSwitch: React.FC = () => {
  const webAppearance = useAppSelector(
    (state) => state.configurations.webAppearance
  );
  const theme = useAppSelector((state) => state.theme.mode);

  const appearance = useAppSelector((state) => state.theme);

  const token = tokenService.hasAccessTokens();

  const darkMode = token ? webAppearance.code === "2" : theme === "1";

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onClickHandler = useCallback(() => {
    const mode = darkMode ? "1" : "2";

    if (token) {
      dispatch(
        saveConfig({
          webAppearance: {
            code: mode,
          },
        })
      );
    }

    dispatch(themeSliceActions.toggleTheme());
    localStorage.setItem("theme", theme === "1" ? "2" : "1");
  }, [dispatch, darkMode, token, theme]);

  return (
    <Tooltip
      title={t("components.themeSwitch.change_theme")}
      placement="left-end"
    >
      <motion.div
        animate={{ rotate: darkMode ? 180 : 0 }}
        transition={{ type: "tween", duration: 0.5, ease: "easeOut" }}
      >
        <IconButton
          onClick={onClickHandler}
          color={appearance.headerIconColor ? undefined : "inherit"}
          size="small"
          sx={
            appearance.headerIconColor
              ? { color: appearance.headerIconColor }
              : undefined
          }
        >
          <ContrastIcon
            color={appearance.headerIconColor ? undefined : "action"}
            fontSize="small"
          />
        </IconButton>
      </motion.div>
    </Tooltip>
  );
};

export default ThemeSwitch;
