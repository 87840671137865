import { Box, Grid, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FC, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { INavigationLink } from "../../../models/navigation";
import ModalComponent from "../Modal";
import DynamicIcon from "./DynamicIcon";

const SideMenuItem: FC<INavigationLink> = ({
  iconClass,
  link,
  name,
  onlyIcon = false,
  end,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  let editedIcon = <DynamicIcon icon={iconClass} color="inherit" />;

  if (onlyIcon) {
    editedIcon = (
      <Tooltip title={name}>
        <Box>
          <DynamicIcon icon={iconClass} color="inherit" />
        </Box>
      </Tooltip>
    );
  }

  const onCloseHandler = () => setOpen(false);

  return (
    <>
      <ModalComponent open={open} onClose={onCloseHandler}>
        <Grid container>
          <Typography variant="h6">
            {t("components.menu.whatsapp.title")}
          </Typography>
          <Typography variant="subtitle1">
            {t("components.menu.whatsapp.description")}
          </Typography>
        </Grid>
      </ModalComponent>
      <NavLink
        to={link === "/whatsapp" || !!onClick ? location.pathname : link}
        onClick={
          onClick
            ? onClick
            : link === "/whatsapp"
            ? () => setOpen(true)
            : undefined
        }
        end={end}
        style={({ isActive }) => ({
          textDecoration: "none",
          color:
            link === "/whatsapp" || !!onClick
              ? theme.palette?.text?.secondary
              : isActive
              ? theme.palette?.primary.main
              : theme.palette?.text?.secondary,
        })}
      >
        <Stack
          direction={"row"}
          gap={1}
          padding={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {editedIcon}
          {!onlyIcon && <Typography variant="body2">{name}</Typography>}
        </Stack>
      </NavLink>
    </>
  );
};

export default memo(SideMenuItem);
