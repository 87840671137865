import { useFetch } from "@4uhub/lib4uhub";
import ExtensionService from "../services/extension.service";
import { useCallback } from "react";

const extensionService = new ExtensionService();
export const useExtensionConfigs = () => {
  const { sendRequest: extensionConfig, loading: loadingExtensionConfigs } =
    useFetch(extensionService.getExtensionConfig);

  const fetchExtensionConfig = useCallback(
    async (ExtensionCode: string, Identifier: string) => {
      const { data, success } = await extensionConfig({
        ExtensionCode,
        Identifier,
      });
      if (data && success) {
        return data[0];
      }
    },
    [extensionConfig]
  );
  return { fetchExtensionConfig, loadingExtensionConfigs };
};
